import Donate from 'assets/banner_support.svg';

export const BANNER_ITEMS = [
  {
    id: 1,
    category: 'EVENT',
    link: '/donate',
    image: Donate,
  },
];
